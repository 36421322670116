import styles from "../../css/modules/whatWeOffer.module.scss";
import OfferDiscSection from "./OfferDiscSection";
import { useState, useEffect } from "react";
import Image from "next/image";
const WhatWeOffer = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const data = [
        {
            number: "01",
            title: "Recruitment Consulting",
            description:
                "With our end-to-end expert recruitment consulting, we identify the best-fit candidates for your business needs, giving you a curated shortlist to speed up your hiring and interview process.",
            image: "/Images/HomepageRevamp/group1.svg",
            alt: "group1",
        },
        {
            number: "02",
            title: "Interview Outsourcing",
            description:
                "Found the right candidates, but short on time and tech expertise to interview them? Tacnique’s domain experts will interview your ideal candidates for you.",
            image: "/Images/HomepageRevamp/group2.svg",
            alt: "group2",
        },
        {
            number: "03",
            title: "BOT Build, Operate, Transfer",
            description:
                "We build a team of tech experts, collaborate on your projects, and transfer the entire team to you on your mark.",
            image: "/Images/HomepageRevamp/group3.svg",
            alt: "group3",
        },
    ];
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
        }, 5000); // Change slide every 5 seconds
        return () => clearInterval(interval);
    }, [data.length]);
    return (
        <div className="max-w-7xl mx-auto"> 
            <div className="flex flex-col items-center justify-center gap-6 pt-8 pb-16 px-6">
                <h1 className={`text-5xl font-bold ${styles.offerh1}`}>
                    What We Offer?
                </h1>
                <p className={styles.offerp}>
                    Tacnique excels as the{" "}
                    <span className="text-[#178F9D] font-bold">
                        {" "}
                        fastest talent hiring agency{" "}
                    </span>{" "}
                    in India offering tailored recruitment consulting, Interview
                    as a Service (IaaS), and BOT solutions for Design,
                    Engineering, and Product roles utilizing AI-driven
                    evaluation methods to meet your specific hiring needs.
                </p>
            </div>
            <div className={styles.offerwrapper}>
                {/* Left Section */}
                <div className={styles.leftcontainer}>
                    {data.map((value, index) => (
                        <div
                            key={index}
                            onClick={() => setActiveIndex(index)}
                            className={`cursor-pointer ${
                                activeIndex === index ? styles.active : ""
                            }`}
                        >
                            <OfferDiscSection
                                {...value}
                                isActive={activeIndex === index}
                            />
                        </div>
                    ))}
                </div>
                {/* Right Section */}
                <div className={styles.rightcontainer}>
                    {data.map((item, index) =>
                        activeIndex === index ? (
                            <div key={index} className={styles.imageActive}>
                                <Image
                                    width={1000}
                                    height={1000}
                                    src={item.image}
                                    alt={item.alt}
                                />
                            </div>
                        ) : null
                    )}
                </div>
            </div>
        </div>
    );
};
export default WhatWeOffer;