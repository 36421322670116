import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import styles from "../../css/modules/stats.module.scss";
const Stats = () => {
    const [triggerCount, setTriggerCount] = useState(false);

    const stats = [
        { value: 1, label: "Profiles to choose from", suffix: "M+" },
        { value: 10, label: "Successful Matches", suffix: "K+" },
        { value: 50, label: "Insights per Candidate", suffix: "+" },
        { value: 80, label: "Faster Hiring Process", suffix: "%" },
        { value: 72, label: "Interview Turnaround", suffix: " Hrs" },
        { value: 10, label: "Technical Skills Evaluated", suffix: "+" },
    ];
    useEffect(() => {
        // Trigger the animation on every visit
        setTriggerCount(false);
        setTimeout(() => {
            setTriggerCount(true);
        }, 2000); // Small delay to reset animation cleanly
    }, []); // Triggers on every component mount
    return (
        <div className={`flex justify-center items-center font-sans bg-[#F2FBFC] py-20 ${styles.statsmaincontainer}`}>
            <div className={`flex flex-col items-center w-2/3 h-2/3 bg-[url('/home/world-map.svg')] bg-no-repeat bg-cover ${styles.statsdiv}`}>
                <h1 className={`w-[652px] text-5xl text-center font-bold text-black leading-[60.2px] ${styles.statsh1}`}>
                    Why is Tacnique the{" "}
                    <br />
                    <span className="text-[#15808D]">
                        Recruitment Leader in India
                    </span>
                </h1>
                <p className={`text-lg text-center leading-6 ${styles.statsp}`}>
                    As a leading recruitment consulting agency, we offer
                    tailored recruitment consulting services, Interview as a
                    Service (IaaS), and BOT solutions, powered by our expertise
                    and AI-driven evaluation methods to meet your specific
                    hiring needs.
                </p>
                <div
                    className={`mt-20 w-full grid grid-cols-3 gap-6 text-center bg-cover bg-center ${styles.statscountmaincontainer}`}
                    style={{
                        backgroundImage: "url('/background-image.jpg')",
                    }}
                >
                    {stats.map((stat, index) => (
                        <div key={index} className="bg-transparent flex w-full">
                            <div className="h-full w-[2px] mr-3 bg-gradient-to-b from-[#15808D] to-[#FFFFFF]" />
                            <div className={styles.statscountcontainer}>
                                <div className={`text-5xl text-left font-extrabold text-gray-800 ${styles.statscount}`}>
                                    {triggerCount && (
                                        <CountUp
                                            start={0}
                                            end={stat.value}
                                            duration={2}
                                            suffix={stat.suffix}
                                        />
                                    )}
                                </div>
                                <p className={`text-[#8B8C9C] font-semibold text-lg mt-2 ${styles.statscountp}`}>
                                    {stat.label}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Stats;
