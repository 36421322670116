
import Image from "next/image";
import styles from "../../css/modules/helpBusiness.module.scss";
import React from "react";
const HelpBusiness = () => {
    let carddata=[
        {
            image:'/Images/HomepageRevamp/HelpB/how_to_reg.png',
            alt:'how_to_reg',
            title:'Tailored Recruitment Strategies',
            description:'We work on custom industry needs and offer end-to-end recruitment consulting, interview outsourcing, and BOT models that suit you.'
        },
        {
            image:'/Images/HomepageRevamp/HelpB/groups.png',
            alt:'groups',
            title:'Extensive Pool of Talent',
            description:'You can draw exceptional candidates from our database with 1 Million+ profiles of diverse skills and competencies for quick screening.'
        },
        {
            image:'/Images/HomepageRevamp/HelpB/document_scanner.png',
            alt:'document_scanner',
            title:'In-depth Screening Process',
            description:'The screening process for candidates includes in-depth interviewing, skill assessment, and background verification for enhanced experience.'
        },
        {
            image:'/Images/HomepageRevamp/HelpB/currency_rupee_circle.png',
            alt:'currency_rupee_circle',
            title:'No Strings Attached Policy',
            description:'We ensure you only pay when you hire the best talent successfully. There will be no charges if our suggestions do not align.'
        },
        {
            image:'/Images/HomepageRevamp/HelpB/support_agent.png',
            alt:'support_agent',
            title:'Continuous Support and Consultation',
            description:'We believe in long-term relationships with our clients and provide continuous support throughout the process, and feedback.'
        },
        {
            image:'/Images/HomepageRevamp/HelpB/flowchart.png',
            alt:'flowchart',
            title:'Streamlined hiring process',
            description:'Our structured interview processes are recorded and handled by our domain experts for transparency and accountability in hiring.'
        },
    ]
    return ( 
       <>
        <div className="pt-20 font-sans flex justify-center">
            <h1 className={`w-7/12 font-bold text-5xl text-center ${styles.HelpBusinessHeading}`}>
                How do We Help Businesses <br />
                <span className="text-[#15808D]">Find the Right Talent?</span>
            </h1>
        </div>
        <p className={`text-lg text-center leading-6 mx-auto mt-4 ${styles.HelpBusiness}`}>
            Our approach as a top-notch recruitment consulting agency ensures that we help you identify the right candidates who possess the key skills required by your organisation.
        </p>
        <div className={`max-w-7xl ${styles.HelpCardscontainer}`}>
            {carddata.map((card,index)=>{
                return(
                <div className={styles.HelpCards}>
                    <Image height={34} width={34} src={card.image} alt={card.alt} loading="lazy"/>
                    <h3>{card.title}</h3>
                    <p>{card.description}</p>
                </div>)
            })}
        </div>
    </> 
    );
};
export default HelpBusiness;
