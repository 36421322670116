import { cloudHiring } from "@/constants";
import Image from "next/image";
import styles from "../../css/modules/hiringchallenges.module.scss";
const HiringChallenges = () => {
    return (
        <div className="">
            <section
                id="secondary-features"
                aria-label="Features for simplifying everyday business tasks"
                className={`font-sans mt-10 pt-20 sm:pt-32 pb-10 ${styles.sectioncontainer}`}
            >
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl flex flex-col items-center">
                        <h2 className={`text-5xl font-bold text-slate-900 sm:text-4xl ${styles.hiringtitles}`}>
                            Why does Tacnique suit you better?
                        </h2>
                        <p className={`mt-4 text-lg w-[586px] text-center leading-6 font-medium text-gray-500 ${styles.hiringp}`}>
                            At Tacnique, we pride ourselves on being more than
                            just a recruitment consulting agency, we are your
                            strategic partner in talent acquisition.
                        </p>
                    </div>
                    <div className={`lg:mt-16 ${styles.hiringcardscontainer}`}>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
                            role="tablist"
                            aria-orientation="horizontal"
                        >
                            {cloudHiring?.map((value, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="transform transition duration-500 rounded-lg bg-[#F5FEFF] hover:shadow-lg p-5"
                                        data-test-id={`cloud-${index}`}
                                    >
                                        <div className="relative">
                                            <div className="w-10 rounded-full bg-secondary-50 p-1.5">
                                                <Image
                                                    src={
                                                        value?.icon ||
                                                        "/loading-placeholder.png"
                                                    }
                                                    alt={value?.icon}
                                                    width={40}
                                                    height={40}
                                                    loading="lazy"
                                                />
                                            </div>
                                            <h3
                                                className="text-[22px] font-semibold text-black"
                                                data-test-id={`cloud-${index}-title`}
                                            >
                                                <span className="absolute inset-0" />
                                                {value.title}
                                            </h3>
                                            <p
                                                className="mt-4 font-normal text-lg leading-6 text-[#4A5568]"
                                                data-test-id={`cloud-${index}-description`}
                                            >
                                                {value.desc}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default HiringChallenges;
