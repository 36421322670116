import React from "react";
import styles from "../../css/modules/offerDiscSection.module.scss";
const OfferDiscSection = ({ number, title, description, isActive }) => {
    return (
        <div
            className={`${styles.OfferDisccontainer} ${
                isActive ? styles.active : styles.inactive
            }`}
        >
            <h1
                className={`text-4xl font-extrabold ${styles.OfferDiscnumber} ${
                    isActive ? styles.active : styles.inactive
                }`}
            >
                {number}
            </h1>
            <h2
                className={`${styles.offerdisch2} ${
                    isActive ? styles.active : styles.inactive
                }`}
            >
                {title}
            </h2>
            <p className={styles.offerdiscp}>{description}</p>
        </div>
    );
};
export default OfferDiscSection;
