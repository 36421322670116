import HeaderNav from "../commonlayout/HeaderNav";
import HiringChallenges from "./HiringChallenges";
import BrandingLogos from "./BrandingLogos";
import { useRouter } from "next/router";
import { getHeader } from "@/utilities";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Typed from "typed.js";
import {
    FAQAccordion,
    FAQSectionText,
    InfoSectionFeatures,
    ReviewSection,
} from "./Cloud/CloudComponents";
import HeaderNavEng from "../commonlayout/HeaderNavEng";
import CImage from "@/components/layouts/CImage";
import dynamic from "next/dynamic";
import Stats from "./Stats";
import WhatWeOffer from "./WhatWeOffer";
import HelpBusiness from "./HelpBusiness";
import Testimonials from "./Testimonials";
const Header = dynamic(() => import("@/components/commonlayout/Header"));
const Contact = dynamic(() => import("@/components/commonlayout/Contact"));
const RedundantHeroImg = () => {
    return (
        <div className="w-[500px] max-w-[70vw] relative min-h-[500px]">
            <CImage
                height="325"
                width="325"
                src={"/home/insight-ellipse.svg"}
                alt={"insights-ellipse"}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full"
                loading={"lazy"}
            />
            <CImage
                height="406"
                width="540"
                src={"/home/redundant_info.png"}
                alt={"redundant_info"}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full"
                loading={"lazy"}
            />
        </div>
    );
};
const HiringInfo = () => {
    return (
        <div className="w-[500px] max-w-[70vw] relative min-h-[500px]">
            <CImage
                height="325"
                width="325"
                src={"/home/insight-ellipse.svg"}
                alt={"insights-ellipse"}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full max-w-[70vw]"
                loading={"lazy"}
            />
            <CImage
                height="117"
                width="85"
                src={"/home/insight-ellipse-2.svg"}
                alt={"insight-ellipse2"}
                className="absolute top-1/2 left-1/2 translate-x-[75%] -translate-y-[170%] "
                loading={"lazy"}
            />
            <CImage
                height="484"
                width="780"
                src={"/home/hiring_info.png"}
                alt={"hiring_info"}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full scale-150 "
                loading={"lazy"}
            />
        </div>
    );
};
const Home = (props) => {
    const isEngineer = props.page === "engineers";
    const router = useRouter();

    const { data } = useSelector(state => state.contentful),
        content = data?.['5H6p09BqsABlm4LeKM8CDF'],
        faqs = data?.['faqs']?.candidate || []

    return (
        <div>
            {getHeader(router.pathname)}
            <Header isEngineer={isEngineer} />
            {isEngineer ? (
                <>
                    <HeaderNavEng />
                    <BrandingLogos
                        isEngineer={isEngineer}
                        heading={"Top tech companies who trust us"}
                        footing={
                            "The tech elite relies on our unmatched interview assessment to scale their company."
                        }
                    />
                    <InfoSectionFeatures infoSections={infoSections} />
                    <section className="bg-ellipse py-4 pb-20 md:-mb-24">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <FAQSectionText
                                isEngineer={isEngineer}
                                description={
                                    "Find speedy and trustworthy solutions to your queries about our cutting-edge cloud-based interview platform, virtual interview questions, engineering interview, and more."
                                }
                            />
                            <FAQAccordion items={faqs} />
                        </div>
                        <div className='bg-[url("/home/ellipse36.svg")] bg-no-repeat bg-cover'>
                            <ReviewSection
                                heading={"Tasting success with Tacnique"}
                                description={
                                    "Hear from real engineers who have landed their dream job with Tacnique's game-changing cloud interview platform."
                                }
                            />
                        </div>
                    </section>
                </>
            ) : (
                <>
                    <HeaderNav />
                    <WhatWeOffer />
                    <Stats />
                    <HelpBusiness />
                    <HiringChallenges />
                    <Testimonials />
                </>
            )}
            <div className='bg-[url("/home/ellipse36.svg")] bg-no-repeat bg-cover'>
                <Contact />
            </div>
        </div>
    );
};
const infoSections = [
    {
        title: "No More Redundant \n Interviews",
        description:
            "Job hunting as a software engineer can be grueling: 10-15 applications a week, 8-10 hours on paperwork, and more time preparing for interviews. It's time to make your dream career a reality.",
        heroImg: "/home/redundant_img.png",
    },
    {
        title: "One Hiring Platform \n Multiple Offers",
        description:
            "Experience the future of tech hiring as Tacnique revolutionizes the job hunt for job seekers. No more endless scrolling, just personalized job offers based on your profile.",
        hero: HiringInfo,
    },
    {
        title: "Land Your \n Dream Job",
        description:
            "We quantify your profile based on your skills. Our hiring solution saves time by repurposing your interview and suggesting opportunities suited to your strengths.",
        hero: RedundantHeroImg,
    },
];
const items = [
    {
        title: "What are the primary parameters you use to evaluate a candidate?",
        description:
            "At Tacnique, we've developed a cutting-edge candidate evaluation process that leaves no stone unturned. Our comprehensive approach entails deep diving into key insights from our rigorous tech interview. We meticulously evaluate various factors such as behavioral competencies, knowledge competencies, and coding & problem-solving skills. Each section is further elaborated into 30+ insights to ensure you select only the best candidate for your team.",
    },
    {
        title: "What is the interview process and format?",
        description:
            "The interview process is a carefully crafted and thought-out affair that aims to uncover the best talent in the tech industry. The format is split into three parts, each designed to test your skills and expertise in different areas. \n \n \n" +
            `${String.fromCharCode(
                8226
            )} The introduction section, which lasts for 5 minutes, allows you to showcase your personality and communication skills. This gives technical interviewers an insight into how well you can collaborate and work in a team. \n \n` +
            `${String.fromCharCode(
                8226
            )} The project discussion section, which lasts for 10 minutes, focuses on your ability to understand complex project requirements and develop innovative solutions. \n \n` +
            `${String.fromCharCode(
                8226
            )} The coding and problem-solving section, which lasts for 30 minutes, is where you will be asked to demonstrate your technical abilities. You will be given a series of coding challenges and problems to solve. \n \n` +
            `${String.fromCharCode(
                8226
            )} The wrap-up section lasts for 15 minutes, where you can ask any questions you may have and wrap up the interview. However, if you are an associate software engineer or junior QA engineer, the wrap-up will only last 5 minutes. \n \n`,
    },
    {
        title: "Which are the top tech companies you partner with, and the positions are they currently hiring for?",
        description:
            "We work with an impressive array of renowned companies that span diverse industries, from real estate and finance to healthcare and technology. Some of our esteemed clients include VTS, Zamp Finance, Medikabazaar, Solutelabs, Visa, Medicine Patent Pool, Alhabtoor, Zillow, JLL, and many more. \n \n " +
            "These companies seek talented software engineering and coding professionals for various positions requiring expertise in cutting-edge web technologies, such as Javascript, Python, Ruby, and more. Additionally, our clients seek individuals proficient in cloud technologies like AWS, GCP, Azure, and other industry-leading platforms.",
    },
    {
        title: "Can I see the interviewer's profile?",
        description:
            "Our experienced technical interviewers conduct thorough and unbiased assessments of your skills. To ensure complete transparency, we offer access to the interviewer's profile, including their name, skills, number of interviews conducted, rating, and other relevant information. This information is accessible in the interview evaluation report, providing a comprehensive understanding of the interviewer's qualifications.",
    },
];
export const HeroSection = ({
    title,
    description,
    hero,
    titleHeadings,
    titleSecondary,
}) => {
    const el = useRef(null);
    const typed = useRef(null);
    useEffect(() => {
        if (el.current) {
            const options = {
                strings: titleHeadings,
                typeSpeed: 50,
                backSpeed: 50,
                loop: true,
            };
            typed.current = new Typed(el.current, options);
            return () => typed.current.destroy();
        }
    }, [el]);
    return (
        <section className="py-10 pb-0 mt-16">
            <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
                <div className="w-full md:w-1/2 flex flex-col items-center md:items-start space-y-4">
                    <div className="w-full px-4 md:px-16 md:-mr-12 pr-0 mx-auto md:ml-auto space-y-8">
                        {titleHeadings ? (
                            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl capitalize">
                                {titleSecondary}
                                <br />
                                <div className="flex text-primary-600">
                                    <h1
                                        className="text-3xl tracking-tight font-extrabold  sm:text-5xl md:text-5xl"
                                        style={{ whiteSpace: "pre" }}
                                        ref={el}
                                    />
                                </div>
                            </h1>
                        ) : (
                            <h1
                                className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        )}
                        <p className="text-left mt-3 text-xl text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-2xl lg:mx-0">
                            {description}
                        </p>
                    </div>
                </div>
                <div className="w-full md:w-1/2 pt-10">{hero}</div>
            </div>
        </section>
    );
};

export default Home;
