import styles from "../../css/modules/testimonials.module.scss";
import Image from "next/image";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
const Testimonials = () => {
    const slickRef = React.useRef(null);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
    };
    let leftdata = [
        {
            name: "Arjun Mehta",
            post: "CEO of Innovatech Solutions, India",
            image: "/Images/HomepageRevamp/1.png",
            alt: "Arjun Mehta",
            description:
                "What I loved most about Tacnique was how quickly they took our feedback and made adjustments. They really made the whole recruitment process so much easier.",
        },
        {
            name: "Priya Kapoor",
            post: "CMO of GreenEarth Industries, India",
            image: "/Images/HomepageRevamp/2.png",
            alt: "Priya Kapoor",
            description:
                "Finding Indian talents became very easy with Tacnique! I was amazed by their constant support on onboarding of the candidates quickly!",
        },
    ];
    let centerdata = [
        {
            name: "Michael Roberts",
            post: "COO of Stellar Innovations, USA",
            image: "/Images/HomepageRevamp/3.png",
            alt: "Michael Roberts",
            description:
                "I worked with the Tacnique team on the BOT model. They were extremely responsive and helpful in building a solid team, managing services, and smooth transfer.",
        },
        {
            name: "Emily Davis",
            post: "Chief HR Officer at NexaCorp, USA",
            image: "/Images/HomepageRevamp/4.png",
            alt: "Emily Davis",
            description:
                "My experience with Tacnique team was good! We wanted to build our team quickly without compromising on quality. Overall a very friendly, customer focused, very reliable service.",
        },
    ];
    let rightdata = [
        {
            name: "David Martinez",
            post: "HR Manager at Apex Innovations, USA",
            image: "/Images/HomepageRevamp/5.png",
            alt: "David Martinez",
            description:
                "I thank the Tacnique Team for extraordinary customer support across time zones and prompt results. I would hire them in the future, too!",
        },
        {
            name: "John Anderson",
            post: "CEO of QuantumLeap Technologies, USA",
            image: "/Images/HomepageRevamp/6.png",
            alt: "John Anderson",
            description:
                "Tacnique’s services were incredible! Their expert panels made interviewing for multiple roles smooth, helping us hire top talent.",
        },
    ];
    const mergedData = [...leftdata, ...centerdata, ...rightdata];
    return (
        <div
            className={` lg:bg-[url('/Images/HomepageRevamp/bgimg.png')] bg-no-repeat bg-cover font-sans mx-auto px-4 max-w-full mt-10  sm:pt-20 pb-10 ${styles.Testimonialcontainer}`}
        >
            <div className="mx-auto max-w-2xl flex flex-col items-center ">
                <h2
                    className={`text-5xl font-bold text-slate-900 sm:text-4xl ${styles.Testimonialtitles}`}
                >
                    Testimonials
                </h2>
                <p
                    className={`mt-4 text-lg w-[586px] text-center leading-6 font-medium text-gray-500 ${styles.Testimonialp}`}
                >
                    We are committed to delivering service excellence which is
                    reflected in your reviews.
                </p>
            </div>
            <div className={`max-w-7xl mx-auto ${styles.cardsmaincontainer}`}>
                <div className={styles.cardscontainerdiv}>
                    {leftdata.map((card, index) => {
                        return (
                            <div
                                className={`p-6 bg-white ${styles.singleCardContainer}`}
                            >
                                <div>
                                    <div>
                                        <p className={styles.descriptionp}>
                                            {card.description}
                                        </p>
                                    </div>
                                    <div
                                        className={`flex justify-between ${styles.namepostDiv}`}
                                    >
                                        <div>
                                            <p className={styles.namep}>
                                                {card.name}
                                            </p>
                                            <p className={styles.postp}>
                                                {card.post}
                                            </p>
                                        </div>
                                        <Image
                                            height={50}
                                            width={50}
                                            src={card.image}
                                            alt={card.alt}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.cardscontainerdiv}>
                    {centerdata.map((card, index) => {
                        return (
                            <div
                                className={`p-6 bg-white ${styles.singleCardContainer}`}
                            >
                                <div>
                                    <div>
                                        <p className={styles.descriptionp}>
                                            {card.description}
                                        </p>
                                    </div>
                                    <div
                                        className={`flex justify-between ${styles.namepostDiv}`}
                                    >
                                        <div>
                                            <p className={styles.namep}>
                                                {card.name}
                                            </p>
                                            <p className={styles.postp}>
                                                {card.post}
                                            </p>
                                        </div>
                                        <Image
                                            height={50}
                                            width={50}
                                            src={card.image}
                                            alt={card.alt}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.cardscontainerdiv}>
                    {rightdata.map((card, index) => {
                        return (
                            <div
                                className={`p-6 bg-white ${styles.singleCardContainer}`}
                            >
                                <div>
                                    <div>
                                        <p className={styles.descriptionp}>
                                            {card.description}
                                        </p>
                                    </div>
                                    <div
                                        className={`flex justify-between ${styles.namepostDiv}`}
                                    >
                                        <div>
                                            <p className={styles.namep}>
                                                {card.name}
                                            </p>
                                            <p className={styles.postp}>
                                                {card.post}
                                            </p>
                                        </div>
                                        <Image
                                            height={50}
                                            width={50}
                                            src={card.image}
                                            alt={card.alt}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.mobilecardscontainerdiv}>
                <div className={`${styles.mobilecardscontainer} `}>
                    <Slider
                        {...settings}
                        ref={slickRef}
                        afterChange={(index) => setActiveIndex(index)}
                    >
                        {mergedData.map((item, index) => {
                            const { description, image, alt, name, post } =
                                item;
                            return (
                                <div className={`${styles.mobilecardwrapper}`}>
                                    {/* Tile content */}
                                    <div>
                                        <p
                                            className={`${styles.mobilecarddesc} bg-[url('/Images/HomepageRevamp/svg.png')] bg-no-repeat `}
                                        >
                                            {description}
                                        </p>
                                    </div>
                                    <div
                                        className={`pt-6 mt-5 pb-0 mb-0 ${styles.mobiletestimonialcardnpi}`}
                                    >
                                        <div>
                                            <p
                                                className={`${styles.mobilecardname}`}
                                            >
                                                {name}
                                            </p>
                                            <p
                                                className={`${styles.mobilecardpost}`}
                                            >
                                                {post}
                                            </p>
                                        </div>
                                        <Image
                                            height={44}
                                            width={44}
                                            src={image}
                                            alt={alt}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                    {/* Bottom-Right Arrows */}
                    <div className={`${styles.mobilecardsarrowdiv}`}>
                        <span
                            className={`${styles.mobilecardprevarrow}`}
                            onClick={() => slickRef.current.slickPrev()}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </span>
                        <span
                            className={`${styles.mobilecardprevarrow}`}
                            onClick={() => slickRef.current.slickNext()}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
