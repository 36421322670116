import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import ModalVideo from "react-modal-video";
import { TypeAnimation } from "react-type-animation";
import styles from "../../css/modules/headerNav.module.scss";

const HeaderNav = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="relative bg-white overflow-hidden">
            <div className="max-w-7xl mx-auto">
                <div className="lg:mt-48 mt-40 md:mt-40 relative z-10 bg-white lg:w-full">
                    <main className="mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-24 lg:mt-20 lg:px-8 xl:mt-24">
                        <div className="flex flex-col justify-center items-center font-sans">
                            <h1 className={`text-5xl font-extrabold text-black ${styles.Heading}`}>
                                Build your{" "}
                                <TypeAnimation
                                    sequence={[
                                        "Tech",
                                        5000,
                                        "Engineering",
                                        5000,
                                        "Design",
                                        5000,
                                        "Product",
                                        5000,
                                        "Tech Support",
                                        5000,
                                        "Data Science",
                                        5000,
                                        "AI",
                                    ]}
                                    wrapper="span"
                                    cursor={true}
                                    repeat={Infinity}
                                    className="text-[#15808D]"
                                />
                                Team in{" "}
                                <span className="text-[#FA6262]">7 days!</span>
                            </h1>
                            <p className={` w-[822px] pt-4 text-[20px] font-medium text-center leading-[27.28px] text-[#8B8C9C] ${styles.headingdesc}`}>
                                Streamline your hiring process with{" "}
                                <span className="font-semibold text-[#15808D]">
                                    India's leading recruitment consultant
                                </span>
                                , offering expert end-to-end recruitment
                                solutions for quick and qualified tech talent
                                hiring.
                            </p>
                            <div className={`mt-5 sm:mt-8 space-y-9 md:space-y-0 sm:flex sm:justify-center lg:justify-start ${styles.headingButton}`}>
                                <div>
                                    <Link href="/demo">
                                        <a
                                            target="_blank"
                                            className="bg-[#15808D] w-full flex items-center justify-center px-9 py-2 text-sm font-semibold rounded-full text-white"
                                        >
                                            Hire Now
                                        </a>
                                    </Link>
                                </div>
                                <div className={`mt-2 md:mt-0 md:ml-5 ${styles.firstfoldbutton}`}>
                                    <ModalVideo
                                        channel="youtube"
                                        autoplay
                                        isOpen={isOpen}
                                        videoId="RCnFXG7vg70"
                                        onClose={() => setOpen(false)}
                                    />
                                    <button
                                        type="button"
                                        className="w-full cursor-pointer flex items-center justify-center rounded-full border px-9 py-2 text-sm font-semibold border-[#E2E8F0]"
                                        onClick={() => setOpen(true)}
                                        data-test-id="watch-video"
                                    >
                                        <svg
                                            width="10"
                                            height="9"
                                            viewBox="0 0 10 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.32708 5.41001L1.74408 8.85701C1.59177 8.92628 1.42452 8.95622 1.25764 8.9441C1.09077 8.93198 0.929598 8.87819 0.7889 8.78764C0.648202 8.69709 0.532472 8.57268 0.452312 8.42582C0.372152 8.27895 0.330124 8.11432 0.330078 7.94701V1.05301C0.330124 0.88569 0.372152 0.721059 0.452312 0.574194C0.532472 0.427329 0.648202 0.302924 0.7889 0.212376C0.929598 0.121828 1.09077 0.06803 1.25764 0.0559107C1.42452 0.0437913 1.59177 0.0737379 1.74408 0.143007L9.32708 3.59001C10.1091 3.94501 10.1091 5.05501 9.32708 5.41001Z"
                                                fill="#15808D"
                                            />
                                        </svg>

                                        <span className="ml-2.5">
                                            Watch video
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="pt-10">
                                <Image
                                    src={"/home/dashboard.png"}
                                    alt="event-date"
                                    width={1000}
                                    height={1000}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default HeaderNav;
